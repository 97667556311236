import { ApolloManager } from 'src/helpers/ApolloManager';
import getOrder from './getOrder.graphql';
import createOrder from './createOrder.graphql';
import updateOrder from './updateOrder.graphql';
import getOrdersForUserProduct from './getOrdersForUserProduct.graphql';
import { DeliveryManager } from 'src/helpers/DeliveryManager';

export interface Order {
	id?: string;
	orderDate: Date;
	user: number;
	total?: string;
	currencyCode?: string;
	couponCode?: string;
	product: number;
}

interface OrderResult {
	id: string;
	orderDate: Date;
	user: number;
	total?: string;
	currencyCode?: string;
	couponCode?: string;
	product: {
		id: number;
	};
}

interface OrderFetchResult {
	allOrders: OrderResult[];
}

interface CreateOrderResult {
	createOrder: OrderResult;
}

interface UpdateOrderResult {
	updateOrder: OrderResult;
}

class OrderImplementation {
	public readonly getOrder = async (id: string): Promise<OrderResult | undefined> => {
		try {
			const result = await ApolloManager.client.query<OrderFetchResult>({
				query: getOrder,
				variables: { id },
				fetchPolicy: 'no-cache',
			});
			if (result.data.allOrders && result.data.allOrders.length > 0) {
				return result.data.allOrders[0];
			}
		} catch (error) {
			console.error(`An error occurred fetching order: Error was ${error}`);
		}
		return undefined;
	};

	public readonly createOrder = async (order: Order): Promise<OrderResult | undefined> => {
		try {
			const result = await ApolloManager.client.mutate<CreateOrderResult>({
				mutation: createOrder,
				variables: order,
				fetchPolicy: 'no-cache',
			});
			if (result.data && result.data.createOrder) {
				return result.data.createOrder;
			}
		} catch (error) {
			console.error(`An error occurred creating order: Error was ${error}`);
		}
		return undefined;
	};

	public readonly updateOrder = async (order: Order): Promise<OrderResult | undefined> => {
		try {
			const result = await ApolloManager.client.mutate<UpdateOrderResult>({
				mutation: updateOrder,
				variables: order,
				fetchPolicy: 'no-cache',
			});
			if (result.data && result.data.updateOrder) {
				return result.data.updateOrder;
			}
		} catch (error) {
			console.error(`An error occurred creating order: Error was ${error}`);
		}
		return undefined;
	};

	public readonly upsertOrder = async (order: Order): Promise<OrderResult | undefined> => {
		try {
			const existingOrders = await this.getActiveOrdersForUserProduct(order.user, order.product);
			if (existingOrders.length > 0) {
				order.id = existingOrders[0].id;
				return this.updateOrder(order);
			} else {
				return this.createOrder(order);
			}
		} catch (error) {
			console.error(`An error occurred creating order: Error was ${error}`);
		}
		return undefined;
	};

	getOrdersForUserProduct = async (user: number, product: number): Promise<OrderResult[]> => {
		try {
			const result = await ApolloManager.client.mutate<OrderFetchResult>({
				mutation: getOrdersForUserProduct,
				variables: { user, product },
				fetchPolicy: 'no-cache',
			});
			if (result.data && result.data.allOrders) {
				return result.data.allOrders;
			}
		} catch (error) {
			console.error(`An error occurred fetching orders: Error was ${error}`);
		}
		return [];
	};

	getActiveOrdersForUserProduct = async (user: number, product: number): Promise<OrderResult[]> => {
		const result: OrderResult[] = [];
		const deliveries = await DeliveryManager.getDeliveriesForUser(user);
		let orders = await this.getOrdersForUserProduct(user, product);
		orders = orders.filter((order) => order.product.id == product);
		orders.map((order) => {
			let found = false;
			deliveries?.map((delivery) => {
				if (delivery.order == order.id) found = true;
			});
			if (!found) result.push(order);
		});
		return result;
	};
}

export const OrderManager = new OrderImplementation();
