import React from 'react';
import nanoid from 'nanoid';
import 'antd/dist/antd.css';
import styles from './styles.module.css';

interface Props {
	caption?: string;
	children: React.ReactNode;
	mouseEnterDelay?: number;
	fadeInDuration?: number;
}

interface State {
	showTooltip: boolean;
}

export default class Tooltip extends React.Component<Props, State> {
	uid: string;

	state: State = {
		showTooltip: false,
	};

	constructor(props: Props) {
		super(props);
		this.uid = nanoid();
	}

	render() {
		const { showTooltip } = this.state;
		const style = {
			'--fade-in-delay': `${this.props.mouseEnterDelay ? this.props.mouseEnterDelay : 250}ms`,
			'--fade-in-duration': `${this.props.fadeInDuration ? this.props.fadeInDuration : 100}ms`,
		} as React.CSSProperties;
		return (
			<div
				className={styles.tooltipcontainer}
				onMouseEnter={() => {
					this.setState({ showTooltip: true });
				}}
				onMouseLeave={() => {
					this.setState({ showTooltip: false });
				}}
			>
				{this.props.caption && (
					<div hidden={!showTooltip} className={styles.tooltip} style={style}>
						<div className={styles.overflowEllipsis}>{this.props.caption}</div>
					</div>
				)}
				{this.props.children}
			</div>
		);
	}
}

export { Tooltip };
