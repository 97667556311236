import React from 'react';

interface Props {
	width: number;
	height: number;
}

const ProfileSVG = (props: Props) => (
	<svg
		width={`${props.width}px`}
		height={`${props.height}px`}
		viewBox="0 0 512 512"
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g>
			<g>
				<g>
					<path
						d="M309.333,277.333h149.333c5.888,0,10.667-4.779,10.667-10.667S464.555,256,458.667,256H309.333
				c-5.888,0-10.667,4.779-10.667,10.667S303.445,277.333,309.333,277.333z"
					/>
					<path
						d="M309.333,213.333h149.333c5.888,0,10.667-4.779,10.667-10.667S464.555,192,458.667,192H309.333
				c-5.888,0-10.667,4.779-10.667,10.667S303.445,213.333,309.333,213.333z"
					/>
					<path
						d="M309.333,341.333h149.333c5.888,0,10.667-4.779,10.667-10.667S464.555,320,458.667,320H309.333
				c-5.888,0-10.667,4.779-10.667,10.667S303.445,341.333,309.333,341.333z"
					/>
					<path
						d="M309.333,405.333h85.333c5.888,0,10.667-4.779,10.667-10.667S400.555,384,394.667,384h-85.333
				c-5.888,0-10.667,4.779-10.667,10.667S303.445,405.333,309.333,405.333z"
					/>
					<path
						d="M138.667,64C132.779,64,128,68.779,128,74.667s4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667
				S144.555,64,138.667,64z"
					/>
					<path
						d="M458.667,21.333H53.333C23.915,21.333,0,45.269,0,74.667v362.667c0,29.397,23.915,53.333,53.333,53.333h405.333
				c29.419,0,53.333-23.936,53.333-53.333V74.667C512,45.269,488.085,21.333,458.667,21.333z M490.667,437.333
				c0,17.643-14.357,32-32,32H53.333c-17.643,0-32-14.357-32-32V128h469.333V437.333z M490.667,106.667H21.333v-32
				c0-17.643,14.357-32,32-32h405.333c17.643,0,32,14.357,32,32V106.667z"
					/>
					<path d="M53.333,64c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667S64,80.555,64,74.667S59.221,64,53.333,64z" />
					<path
						d="M53.333,405.333l213.333-0.021c5.888,0,10.667-4.779,10.667-10.667c0-26.731-18.133-49.941-44.011-56.427l-32.021-8
				l-1.344-5.355c8.299-9.173,14.208-20.885,16.661-33.045c5.696-2.944,9.813-8.533,10.667-15.253l2.304-18.56
				c0.704-5.632-1.024-11.307-4.757-15.573c-1.152-1.301-2.475-2.453-3.883-3.413l0.533-11.328l1.941-1.941
				c5.504-5.845,12.949-18.325,1.173-36.331c-5.589-8.555-17.109-18.752-40.192-18.752c-6.805,0-22.144,0-37.035,9.323
				c-43.904,1.557-49.067,25.216-49.067,43.072c0,3.52,0.64,10.112,1.237,15.616c-1.579,1.003-3.029,2.24-4.288,3.669
				c-3.797,4.309-5.547,10.005-4.821,15.659l2.304,18.56c0.875,6.955,5.291,12.715,11.797,15.552
				c2.389,11.627,7.979,22.891,15.765,31.829l-1.557,6.272l-32.021,8c-25.92,6.485-44.053,29.696-44.053,56.448
				c0,2.837,1.109,5.568,3.115,7.552C47.787,404.203,50.496,405.333,53.333,405.333z M91.925,358.891l38.229-9.557
				c3.819-0.96,6.805-3.947,7.765-7.765l4.544-18.197c0.939-3.755-0.235-7.723-3.072-10.368
				c-8.299-7.808-13.803-19.221-14.699-30.528c-0.448-5.525-5.184-8.597-10.752-8.597l-2.752-16.981c3.072,0,5.973-1.301,8-3.605
				c2.027-2.283,2.965-5.333,2.581-8.384c-0.811-6.443-2.112-18.091-2.112-21.845c0-11.051,0-21.483,31.189-21.803
				c2.176-0.021,4.309-0.704,6.101-1.984C167.104,192,178.795,192,184.405,192c11.008,0,18.325,2.965,22.336,9.088
				c4.331,6.635,2.56,8.512,1.365,9.813l-4.629,4.629c-1.877,1.877-2.987,4.373-3.115,7.019l-1.131,23.211
				c-0.128,2.901,0.213,4.928,2.197,7.04c2.005,2.112,4.053,2.539,6.955,2.56l-1.365,17.344c-5.568,0-10.176,4.267-10.624,9.813
				c-0.939,11.499-6.848,23.424-15.424,31.125c-2.923,2.624-4.181,6.677-3.221,10.517l4.352,17.429
				c0.96,3.819,3.925,6.827,7.765,7.765l38.251,9.557c12.843,3.221,22.656,12.907,26.325,25.088l-188.885-0.021
				C69.227,371.819,79.061,362.133,91.925,358.891z"
					/>
					<path d="M96,64c-5.888,0-10.667,4.779-10.667,10.667S90.112,85.333,96,85.333s10.667-4.779,10.667-10.667S101.888,64,96,64z" />
				</g>
			</g>
		</g>
	</svg>
);

export { ProfileSVG };
