import React from 'react';
import styles from './styles.module.css';
import StripeBuyButtonElement from './components/StripeBuyButtonElement';
import { Tooltip } from '../Tooltip/component';
import { config } from 'dotenv';
import { getStripeEnvironmentVariables } from 'src/utils/Config';

interface Props {
	userId?: number;
	email?: string;
	disabled?: boolean;
	currency?: string;
}

class StripeBuyButton extends React.Component<Props> {
	componentDidMount() {
		const stripeScript = document.createElement('script');
		stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
		stripeScript.async = true;
		document.head.appendChild(stripeScript);
	}

	render() {
		const stripeEnvironmentVariables = getStripeEnvironmentVariables();
		let buttonId =
			this.props.currency == 'AUD'
				? stripeEnvironmentVariables.buyButtonIdAud
				: stripeEnvironmentVariables.buyButtonIdUsd;
		return (
			<Tooltip
				caption={
					this.props.disabled
						? 'Please agree to the Terms and Conditions and the End User Agreement'
						: undefined
				}
			>
				<div className={this.props.disabled ? styles.disabled : undefined}>
					<StripeBuyButtonElement
						publishableKey={stripeEnvironmentVariables.publishableKey}
						buttonId={buttonId}
						userId={this.props.userId}
						email={this.props.email}
					/>
				</div>
			</Tooltip>
		);
	}
}

export { StripeBuyButton };
