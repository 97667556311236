export interface CouponCode {
	id: string;
	code: string;
	discount: number;
	description: string;
	active: boolean;
	limitedUses: boolean;
	remainingUses: number;
	shareProfileWithClient: boolean;
	clientName: string;
}

function getDiscount(couponCode: string, couponCodes: CouponCode[]): number | undefined {
	const result = findCouponCodeInList(couponCode, couponCodes);
	return result && (!result.limitedUses || result.remainingUses > 0) ? result.discount : undefined;
}

function findCouponCodeInList(
	couponCode: string,
	couponCodes: CouponCode[]
): CouponCode | undefined {
	const result = couponCodes.find((cc) => cc.code.toLowerCase() === couponCode.toLowerCase());
	return result ? result : undefined;
}

function recalculateOrder(
	goodsTotal: number,
	discountPercent: number
): { discountAmount: number; discountedTotal: number } {
	const discount = round(goodsTotal * discountPercent);
	const orderTotal = round(goodsTotal - discount);
	return { discountAmount: discount, discountedTotal: orderTotal > 0.0 ? orderTotal : 0.0 };
}

function round(value: number): number {
	return Math.round(value * 100) / 100;
}

export { getDiscount, findCouponCodeInList, recalculateOrder };
