import React from 'react';
import styles from './styles.module.css';
import { ProfileSVG } from 'src/components/SVGs/ProfileSVG';

interface CheckoutProductTileProps {
	description1: string;
	description2: string;
	price: number;
	onRemoveClick?: () => void;
}

const CheckoutProductTile = (props: CheckoutProductTileProps) => {
	return (
		<div className={styles.tile}>
			<div className={styles.image}>
				<ProfileSVG width={75} height={75} />
			</div>
			<div className={styles.description}>
				<div className={[styles.description1, styles.truncate].join(' ')}>{props.description1}</div>
				<div className={[styles.description2, styles.truncate].join(' ')}>{props.description2}</div>
			</div>
			<div className={styles.rightContainer}>
				<div className={styles.price}>${props.price}</div>
				{props.onRemoveClick ? (
					<div className={styles.link} onClick={() => props.onRemoveClick && props.onRemoveClick()}>
						REMOVE
					</div>
				) : undefined}
			</div>
		</div>
	);
};

export { CheckoutProductTile };
