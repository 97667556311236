import * as React from 'react';

function StripeBuyButtonElement(props) {
	return (
		<stripe-buy-button
			buy-button-id={props.buttonId}
			publishable-key={props.publishableKey}
			client-reference-id={props.userId}
			customer-email={props.email}
		></stripe-buy-button>
	);
}

export default StripeBuyButtonElement;
