import React from 'react';
import styles from './styles.module.css';
import { navigate } from 'gatsby';
import { Layout } from 'src/components/Layout';
import { ArrowButton } from 'src/components/ArrowButton';
import { CheckoutProductTile } from 'src/components/CheckoutProductTile';
import { KeystoneUser, UserManager } from 'src/helpers/UserManager';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { CouponCodeManager } from 'src/helpers/CouponCodeManager/CouponCodeManager';
import { CouponCode } from 'src/helpers/CouponCodeManager/CouponCode';
import { Product, ProductManager } from 'src/helpers/ProductManager/ProductManager';
import { OrderManager } from 'src/helpers/OrderManager/OrderManager';
import { DeliveryManager } from 'src/helpers/DeliveryManager/DeliveryManager';
import { GroupManager } from 'src/helpers/GroupManager';
import {
	getDiscount,
	findCouponCodeInList,
	recalculateOrder,
} from 'src/helpers/CouponCodeManager/CouponCode';
import { Spinner } from 'src/components/Spinner';
import { Checkbox } from 'src/components/Checkbox';
import { TextInput } from 'src/components/TextInput';
import { StripeBuyButton } from 'src/components/StripeBuyButton';

interface State {
	loading: boolean;
	user: KeystoneUser | undefined;
	product: Product;
	total: number;
	email: string;
	name: string;
	couponCodes: CouponCode[];
	couponCode: string;
	discount: number;
	discountValid?: boolean;
	discountAmount: number;
	discountedTotal: number;
	orderID?: string;
	shareProfileWithClient?: boolean;
	clientName?: string;
	termsConsent: boolean;
	sharingConsent: boolean;
	showStripeButton: boolean;
}

export default class Checkout extends React.Component<State> {
	state: State = {
		loading: true,
		user: undefined,
		product: { id: 0, name: 'No Product Selected', price: 0 },
		total: 0,
		email: '',
		name: '',
		couponCodes: [],
		couponCode: '',
		discount: 0,
		discountAmount: 0,
		discountedTotal: 0,
		shareProfileWithClient: false,
		termsConsent: false,
		sharingConsent: false,
		showStripeButton: true,
	};

	async componentDidMount() {
		const user = await UserManager.getAuthenticatedUser();
		if (!user || user.guest) {
			navigate('/sign-in?url=checkout');
			return;
		}
		const productData = await ProductManager.getActiveProductsForUser(user);
		if (!productData || productData.length < 1) {
			navigate('/');
			return;
		}
		// Window is not accessible during server side deployment, so it must be checked prior to usage as shown here
		if (typeof window === 'undefined') {
			navigate('/');
			return;
		}
		const urlParams = new URLSearchParams(window.location.search);
		const existingID = urlParams ? Number(urlParams.get('p')) : undefined;
		let product = productData.find((p) => p.id === existingID);
		product = product ? product : productData[0];
		if (!product) {
			navigate('/');
			return;
		}
		this.setState({
			loading: false,
			user,
			product,
			total: product.price,
			email: user.email ?? '',
			name: `${user.firstName} ${user.lastName}`,
			couponCodes: await CouponCodeManager.getActiveCouponCodes(),
			discountedTotal: product.price,
			couponCode: (await CouponCodeManager.getMostRecentFreeCouponCodeForUser(user.id))?.code,
		});
		if (this.state.couponCode && this.state.couponCode != '') await this.applyCouponCode();
	}

	async triggerProfileEmail() {
		const { user, orderID, couponCode, couponCodes } = this.state;
		if (user && orderID && user.id) {
			await UserManager.updateUser({ id: user.id, emailingProfile: true });
			await DeliveryManager.createDelivery({
				deliveryDate: new Date(Date.now()),
				email: user.email || '',
				order: orderID,
			});
			await GroupManager.updateUserToAutoAssignedGroups(user.id);
			const cc = findCouponCodeInList(couponCode, couponCodes);
			cc
				? await CouponCodeManager.useCouponCode(cc)
				: console.error(`Unable to use coupon code (not in list)`);
		}
	}

	navigateToOrderComplete() {
		navigate('/order-complete');
	}

	async applyCouponCode() {
		// Redraw 'Debit or Credit Card'; this must include an await
		this.setState({ showStripeButton: false });
		await this.applyCouponCodeHelper();
		this.setState({ showStripeButton: true });
	}

	async applyCouponCodeHelper() {
		const { couponCode, couponCodes } = this.state;
		const discount = getDiscount(couponCode, couponCodes);
		if (discount) {
			const totals = recalculateOrder(this.state.total, discount);
			this.setState({
				...totals,
				discountValid: true,
				...this.getProfileShareDetails(couponCode, couponCodes),
			});
		} else {
			this.setState({
				discountValid: false,
				...this.getProfileShareDetails(couponCode, couponCodes),
			});
		}
	}

	getProfileShareDetails(code: string, couponCodes: CouponCode[]) {
		const item = findCouponCodeInList(code, couponCodes);
		if (item) {
			return {
				shareProfileWithClient: item.shareProfileWithClient,
				clientName: item.clientName ?? 'our partner organisations',
			};
		}
		return {};
	}

	hasUserConsented(): boolean {
		return (
			this.state.termsConsent && (!this.state.shareProfileWithClient || this.state.sharingConsent)
		);
	}

	async logOrder() {
		const { user, discountedTotal, couponCode, product } = this.state;

		if (user && user.id) {
			const order = await OrderManager.upsertOrder({
				orderDate: new Date(Date.now()),
				user: user.id,
				total: String(discountedTotal),
				currencyCode: product.currencyCode,
				couponCode,
				product: product.id,
			});
			if (order) {
				this.state.orderID = order.id;
			}
		}
	}

	render() {
		const {
			loading,
			product,
			email,
			total,
			name,
			discountValid,
			shareProfileWithClient,
			clientName,
			showStripeButton,
		} = this.state;
		const description2 = `Profile for ${name}`;
		const paypalDescription = `${this.state.product.name} | ${description2}`;
		const stripeCurrency = product.currencyCode ?? 'AUD';
		const hasPrice = this.state.discountedTotal != 0;
		const capsuleStyles = this.state.couponCode
			? [styles.capsule, styles.capsuleEnabled]
			: [styles.capsule];
		const labelStyles = this.state.couponCode
			? [styles.label, styles.labelEnabled]
			: [styles.label];

		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						<div>
							<div className={styles.headingContainer}>
								<div className={styles.heading}>Complete your purchase:</div>

								<CheckoutProductTile
									description1={product.name}
									description2={description2}
									price={Number(product.price)}
								/>

								<div className={styles.row}>
									{/* <div className={styles.column}>
										<div className={styles.row}>
											<div className={styles.couponCodeContainer}>
												<form
													onSubmit={(event) => {
														event.preventDefault();
														this.applyCouponCode();
													}}
												>
													<TextInput
														caption={'Coupon Code'}
														onValueChanged={(value: string) =>
															this.setState({ couponCode: value, discountValid: undefined })
														}
														additionalStyles={styles}
														validationMsg={'Invalid Coupon Code'}
														hasError={discountValid === false}
													/>
												</form>
												<div
													className={capsuleStyles.join(' ')}
													onClick={() => this.applyCouponCode()}
												>
													<p className={labelStyles.join(' ')}>Apply</p>
												</div>
											</div>
										</div>
									</div> */}
									<div className={styles.column}>
										{this.state.discountAmount != 0.0 ? (
											<div className={styles.total}>
												SUBTOTAL: $
												{total.toLocaleString(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</div>
										) : null}
										{this.state.discountAmount != 0.0 ? (
											<div className={styles.total}>
												DISCOUNT: $
												{this.state.discountAmount.toLocaleString(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</div>
										) : null}
										<div className={styles.total}>
											TOTAL: $
											{this.state.discountedTotal.toLocaleString(undefined, {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</div>
									</div>
								</div>
							</div>

							<div className={styles.infoContainer}>
								<div className={styles.subHeading}>Delivery Information</div>
								<div className={styles.paragraph}>
									This is an electronic delivery. After payment, we will email {email} with a
									download link.
								</div>

								{hasPrice ? (
									<div>
										<div className={styles.subHeading}>Payment</div>
										<div className={styles.paragraph}>
											We use Stripe to securely process your payment.
										</div>
										<div className={styles.paragraph}>
											If you have a coupon code, please enter it on the Stripe checkout by selecting
											"Add promotion code".
										</div>
									</div>
								) : undefined}

								<div className={styles.subHeading}>Terms &amp; Conditions</div>
								<Checkbox
									caption={
										<>
											I agree to{' '}
											<a href="https://xceptional.io/terms" target="_blank">
												Xceptional’s General Terms and Conditions
											</a>{' '}
											and{' '}
											<a href="https://xceptional.io/end-user-agreement" target="_blank">
												End User Agreement
											</a>
											.
										</>
									}
									checked={this.state.termsConsent}
									onChecked={(value) => {
										this.setState({ termsConsent: value });
									}}
								/>

								{shareProfileWithClient ? (
									<div>
										<div className={styles.consentContainer}>
											<Checkbox
												caption={
													<div>
														I consent for my profile and associated data to be shared with{' '}
														{clientName}.
													</div>
												}
												checked={this.state.sharingConsent}
												onChecked={(value) => {
													this.setState({ sharingConsent: value });
												}}
											/>
										</div>
									</div>
								) : undefined}
							</div>

							<div className={styles.paymentContainer}>
								{hasPrice ? (
									<div className={styles.paymentViaStripe}>
										{showStripeButton ? (
											<StripeBuyButton
												userId={this.state.user?.id}
												email={this.state.user?.email}
												disabled={!this.hasUserConsented()}
												currency={stripeCurrency}
											/>
										) : null}
									</div>
								) : (
									<div className={styles.completeOrderOnly}>
										<ArrowButton
											onClick={async () => {
												await this.logOrder();
												await this.triggerProfileEmail();
												this.navigateToOrderComplete();
											}}
											caption="Complete Purchase"
											isDisabled={!this.hasUserConsented()}
										/>
									</div>
								)}
							</div>
						</div>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
